var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vue-select-basic')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vue-select-multiple')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('vue-select-size')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('vue-select-multi-size')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }