var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Vue-Select"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeVueBasic) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h6', [_vm._v("Basic Select")]), _c('b-card-text', [_c('code', [_vm._v("vue-select")]), _c('span', [_vm._v(" accepts arrays of primitive values or objects to use as ")]), _c('code', [_vm._v("options")]), _c('span', [_vm._v(" through the options prop:")])]), _c('b-form-group', [_c('v-select', {
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "label": "title",
      "options": _vm.option
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h6', [_vm._v("Select with Icon")]), _c('b-card-text', [_c('span', [_vm._v("The current option within the dropdown, contained within ")]), _c('code', [_vm._v("<li>")]), _c('span', [_vm._v(".")]), _c('code', [_vm._v("option {Object}")]), _c('span', [_vm._v(" - The currently iterated option from ")]), _c('code', [_vm._v("filteredOptions")])]), _c('b-form-group', [_c('v-select', {
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.books,
      "label": "title"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref) {
        var title = _ref.title,
            icon = _ref.icon;
        return [_c('feather-icon', {
          staticClass: "align-middle mr-50",
          attrs: {
            "icon": icon,
            "size": "16"
          }
        }), _c('span', [_vm._v(" " + _vm._s(title))])];
      }
    }]),
    model: {
      value: _vm.selected1,
      callback: function callback($$v) {
        _vm.selected1 = $$v;
      },
      expression: "selected1"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }